/**
 * Template for displaying detailed information about an individual project
 */
import React from 'react'
import styled from 'styled-components'
import Layout from 'components/layout'
import Seo from 'components/seo'
import BackLink from 'components/BackLink'
import Landing from 'components/Landing'
import Chip from '@material-ui/core/Chip'
import { Link as MUILink } from '@material-ui/core'

const TechStackContainer = styled.div`
  /* min-height: 80px; */

  height: 20vh;
  margin: 20px;
  display: flex;
  align-content: flex-end;
  /* justify-content: space-evenly; */
`

const DescriptionContainer = styled.div`
  margin: 10px;
  margin-bottom: 40px;
  white-space: pre-wrap;
`

export default function projectTemplate({ project }) {
  console.log('project.coverImage', project.coverImage)
  return (
    <>
      <Landing
        background={project.coverImage?.asset.url}
        backgroundImage={project.coverImage?.asset.gatsbyImageData}
        brightness={0.4}
        height={80}
        title={
          <>
            <h1
              style={{
                paddingLeft: 20,
                color: '#fff',
              }}
            >
              {project.title}
            </h1>
            <div
              style={{
                color: '#3b3b3b',
                backgroundColor: '#fff',
                left: 0,
                paddingLeft: 20,
              }}
            >
              {project.caption}
            </div>
          </>
        }
        caption={project.caption}
      />
      <Layout>
        <Seo title="Project Name" />
        {/* <h2>{project.title}</h2> */}
        <TechStackContainer>
          {project.demoLink && (
            <div
              style={{
                marginRight: 16,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <MUILink href={project.demoLink} target="blank">
                <h3 style={{ margin: 0 }}>Demo</h3>
              </MUILink>
            </div>
          )}

          {project.sourceLink && (
            <div
              style={{
                marginRight: 8,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <MUILink href={project.sourceLink} target="blank">
                <h3 style={{ margin: 0 }}>Source</h3>
              </MUILink>
            </div>
          )}
          <div style={{ flex: 1 }}></div>
          <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <h3 style={{ margin: 0 }}>Tech: </h3>
          </div>
          <div
            style={{
              display: 'flex',
              // flexWrap: 'wrap'
              overflowY: 'auto',
            }}
          >
            {project.stack.map((tech, i) => (
              <Chip
                key={`tech_${i}`}
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                variant="outlined"
                label={tech.title}
                component="a"
                href={tech.link || ''}
                target="blank"
                onClick={() => 0}
              />
            ))}
          </div>
        </TechStackContainer>
        <DescriptionContainer>{project.description}</DescriptionContainer>
        <BackLink />
      </Layout>
    </>
  )
}
