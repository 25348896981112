import React from 'react'
import { Link } from '@material-ui/core'
import { navigate } from 'gatsby'

export default function BackLink() {
  return (
    <div style={{ textAlign: 'center', margin: 20 }}>
      <Link
        color="textPrimary"
        onClick={() => navigate(-1)}
        style={{ cursor: 'pointer' }}
      >
        {'< Back'}
      </Link>
    </div>
  )
}
