import React from 'react'
import { graphql } from 'gatsby'
import ProjectTemplate from 'templates/projectTemplate'

export const query = graphql`
  query($id: String!) {
    sanityProject(id: { eq: $id }) {
      _createdAt
      _updatedAt
      caption
      coverImage {
        alt
        asset {
          url
          gatsbyImageData(fit: FILLMAX)
        }
      }
      id
      description
      demoLink
      slug {
        current
      }
      sourceLink
      title
      stack {
        link
        title
      }
    }
  }
`

export default function Project({ data }) {
  console.log('Project Detail data:', data)
  const project = data.sanityProject
  console.log('Project Detail project:', project)

  return project ? (
    <ProjectTemplate
      project={project}
      img={project.coverImage.asset.url}
      title={project.title}
      caption={project.caption}
      demoLink={project.demoLink}
      sourceLink={project.sourceLink}
      stack={project.stack}
    />
  ) : null
}
